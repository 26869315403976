import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/includes/Header";
import Footer from "./components/includes/Footer";
import Home from "./components/Home";
import Whiskeys from "./components/Pages/Whiskeys";
import Recipes from "./components/Pages/Recipes";
import Regularsrules from "./components/Pages/Regularsrules";
import WheretoBuySeagram from "./components/Pages/WheretoBuySeagram";
import NewsLetter from "./components/Pages/NewsLetter";
import FindNearMe from "./components/Pages/FindNearMe";
import ShopOnline from "./components/Pages/shop-online";
import WhiskeysCarousel from "./components/Pages/whiskeys-carousel";
import NotFound from "./components/Pages/404";
import { Domain_Name } from "./constant";
import { AnimatePresence } from "framer-motion";
import ContactUs from "./components/Pages/ContactUs";
import ThankYou from "./components/Pages/ThankYou";

function App() {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  const location = useLocation();
  const JsonAppDataApi = Domain_Name + "/appData.json";
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await fetch(JsonAppDataApi, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        setStatus(true);
      });
  };

  const pageUrl =  window.location.href;
  const urlPage = pageUrl.includes("whiskey-drinks");

  return (
    <>
      <Header />
      <main id="main" className={urlPage == true ? 'whiskey-page' : '' }>
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path="*" element={<NotFound notFoundKey={status ? data["404"] : ""} />} />
            <Route
              path="/"
              element={<Home homeKey={status ? data.home : ""} />}
            />
            <Route path="/whiskeys" element={<Whiskeys whiskeyKey={status ? data.ourWhiskeys : ""} />} />
            <Route path="/whiskeys/:slug" element={<WhiskeysCarousel />} />
            <Route path="/whiskeys/*" element={<NotFound notFoundKey={status ? data["404"] : ""} />} />
            <Route path="/regularsrules" element={<Regularsrules regularRuleKey={status ? data.regularsrules : ""} />} />
            <Route path="/whiskey-drinks" element={<Recipes recipesKey={status ? data.ourRecipes : ""} />} />
            <Route path="/whiskey-drinks/:slug" element={<Recipes recipesDataKey={""} />} />
            <Route path="/whiskey-drinks/*" element={<NotFound notFoundKey={status ? data["404"] : ""} />} />
            <Route
              path="/where-to-buy-seagrams"
              element={<WheretoBuySeagram whereToBuySeagramKey={status ? data.whereToBuySeagrams : ""} />}
            />
            <Route path="/newsletter" element={<NewsLetter newsLetterKey={status ? data.newsletter : ""} />} />
            <Route
              path="/where-to-buy-seagrams/find-near-me"
              element={<FindNearMe findNearMeKey={status ? data.whereToBuySeagramsFindNearMe : ""} />}
            />
            <Route
              path="/contact-us"
              element={<ContactUs newsLetterKey={status ? data.newsletter : ""}/>}
            />
            <Route
              path="where-to-buy-seagrams/shop-online"
              element={<ShopOnline shopOnlineKey={status ? data.whereToBuySeagramsShopOnLine : ""} />}
            />
            <Route
              path="/thank-you"
              element={<ThankYou newsLetterKey={status ? data.newsletter : ""}/>}
            />
          </Routes>
        </AnimatePresence>
      </main>
      <Footer />
    </>
  );
}

export default App;