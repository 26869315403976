import React from "react";
import Navigation from "./Menu";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation(); // React Hook
  const checkRoute = location.pathname;
  return (
    <>
      <header className={checkRoute === "/regularsrules" ? ('header-white'):null}>
        <Navigation />
      </header>
    </>
  );
};

export default React.memo(Header);