import React, { useEffect } from "react";
import { Badge, Col, Container, Image, Row } from "react-bootstrap";
import { Link, useNavigate  } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Domain_Name } from "../../constant";
import { motion } from "framer-motion";

const Whiskeys = (props) => {
  let navigate = useNavigate();
  useEffect(() => {

const pathname = window.location.pathname; 
console.log(pathname);
if (pathname==='/whiskeys/' || pathname==='/whiskeys'){
  return navigate("/whiskeys/seagrams-blended-whiskey", { replace: true });
}

if (pathname==='/whiskeys/seagrams-honey-whiskey/' || pathname==='/whiskeys/seagrams-honey-whiskey'){
   return navigate("/whiskeys/seagrams-blended-whiskey", { replace: true });
 }

else if (pathname==='/whiskeys/seagrams-apple-whiskey/' || pathname==='/whiskeys/seagrams-apple-whiskey'){
  return navigate("/whiskeys/seagrams-blended-whiskey", { replace: true });
}
    
  }, []); 
  

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{props.whiskeyKey.title}</title>
          <meta name="description" content={props.whiskeyKey.description} />
          <meta name="keywords" content={props.whiskeyKey.keywords} />
          <link rel="icon" href="/favicon.ico" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Seagram's 7" />
          <meta name="twitter:title" content={props.whiskeyKey.title} />
          <meta
            name="twitter:description"
            content={props.whiskeyKey.description}
          />
          <meta property="og:title" content={props.whiskeyKey.title} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={props.whiskeyKey.canonicalTag} />
          <meta property="og:image" content={`${Domain_Name + "/" + props.whiskeyKey.primaryImage}`} />
          <meta
            property="og:description"
            content={props.whiskeyKey.description}
          />
          <link rel="canonical" href={props.whiskeyKey.canonicalTag} />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01]
          }}
          exit={{ opacity: 0 }}
        >
          <Container className="whiskey-data">
            <div>
              <h1 className="lead text-center pb-4">Our Whiskeys</h1>
            </div>
            <Row className="justify-content-center our-whiskey">
              <Col lg={4} md={4} sm={12} className="blended-whiskey">
                <div className="blend-box">
                  <Link to="/whiskeys/seagrams-blended-whiskey">
                    <Image
                      src="/images/flavor/seagrams-blended-whiskey.png"
                      alt="blended" className="img-fluid"
                    />
                    <h2>Seagram's 7 Crown Blended Whiskey</h2>
                  </Link>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12} className="honey-whiskey">
                <div className="honey-box">
                  <Link to="/whiskeys/seagrams-blended-whiskey">
                    <Image
                      src="/images/flavor/seagrams-honey-whiskey.png"
                      alt="honey" className="img-fluid"
                    />
                    <h2>Seagram's 7 Crown  Dark Honey</h2>
                  </Link>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12} className="apple-whiskey">
                <div className="apple-box">
                  <Link to="/whiskeys/seagrams-blended-whiskey">
                    <Image
                      src="/images/flavor/seagrams-apple-whiskey.png"
                      alt="apple" className="img-fluid"
                    />
                    <h2>Seagram's 7 Crown  Apple</h2>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </motion.div>
      </HelmetProvider>
    </>
  );
};

export default Whiskeys;
