import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';


export default function NewsletterSubmitModal(props) {
  const [isOpen, setIsOpen] = useState(props.isShowModal);
  const handleClose = (event) => { props.registerModel(true); event.preventDefault(); };
  const handleThisClose = () => {setIsOpen(false)};
  
  return (
    <>
      <Modal show={isOpen} className='successModal' centered>
        <Modal.Header className="border-0 p-0 m-auto">
          <div className="stayTouch text-center">
            <h2 className="colorRed mt-3">Stay in Touch</h2>
            <p className='colorRed'>
              Sign up today for news, exclusive recipes and the latest events.
            </p>
          </div>
          <button onClick={handleThisClose} type="button" className="btn closeBtn">
            ✖
          </button>
        </Modal.Header>
        <Modal.Body className='p-0 pb-0'>
          <div className="stayTouch text-center">
            <h2 className='colorRed'>{ props.apiResponse.success ? "Success!" : "Error!" }</h2>
            <p className='colorRed mb-2'>{ props.apiResponse.success ? "Thank you for singing up for our newsletter." : "There was an error in your submission.Please try again." }</p>
          </div>
        </Modal.Body>
        { props.apiResponse.success ? "" : <button onClick={handleClose} className='colorRed ms-2 backBtn' >Back</button> }
      </Modal>
    </>
  );
}
