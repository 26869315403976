import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Iframe from "react-iframe";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Domain_Name } from "../../constant";
import { motion } from "framer-motion";
export default function ShopOnline(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{props.shopOnlineKey.title}</title>
          <meta name="description" content={props.shopOnlineKey.description} />
          <meta name="keywords" content={props.shopOnlineKey.keywords} />
          <link rel="icon" href="/favicon.ico" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Seagram's 7" />
          <meta name="twitter:title" content={props.shopOnlineKey.title} />
          <meta
            name="twitter:description"
            content={props.shopOnlineKey.description}
          />
          <meta property="og:title" content={props.shopOnlineKey.title} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={props.shopOnlineKey.canonicalTag} />
          <meta
            property="og:image"
            content={`${Domain_Name + "/" + props.shopOnlineKey.primaryImage}`}
          />
          <meta
            property="og:description"
            content={props.shopOnlineKey.description}
          />
          <link rel="canonical" href={props.shopOnlineKey.canonicalTag} />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01]
          }}
          exit={{ opacity: 0 }}
        >
          <section className="content shop-online">
            <div className="container-fluid">
              <Row>
                <Col sm={12}>
                  <div className="where-to-buy-Headline text-center">
                    <h1 className="lead text-center">Shop Online</h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="find-section text-center">
                  <Iframe
                    url="https://where-to-buy.co/widgets/core/BuyOnlineBuyLocalV2/index.html?pid=12037992"
                    width="100%"
                    height="800px"
                    id="wtbIframe"
                    className="wtbIframe"
                    overflow="hidden"
                    minHeight="20px"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </motion.div>
      </HelmetProvider>
    </>
  );
}