import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Container } from "reactstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Domain_Name } from "../../constant";
import { motion } from "framer-motion";

export default function Regularsrules(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{props.regularRuleKey.title}</title>
        <meta name="description" content={props.regularRuleKey.description} />
        <meta name="keywords" content={props.regularRuleKey.keywords} />
        <link rel="icon" href="/favicon.ico" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="Seagram's 7" />
        <meta name="twitter:title" content={props.regularRuleKey.title} />
        <meta
          name="twitter:description"
          content={props.regularRuleKey.description}
        />
        <meta property="og:title" content={props.regularRuleKey.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={props.regularRuleKey.canonicalTag} />
        <meta property="og:image" content={`${Domain_Name + "/" + props.regularRuleKey.primaryImage}`} />
        <meta
          property="og:description"
          content={props.regularRuleKey.description}
        />
        <link rel="canonical" href={props.regularRuleKey.canonicalTag} />
      </Helmet>
      <div className="regularsrules">
        <Container fluid>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01]
          }}
          exit={{ opacity: 0 }}
        >
            <Row className="sweepstakes">
              <Col>
                <Image
                  className="closed-hero"
                  src="/images/sweepstakes-closed-hero.png"
                  alt="regularsrules"
                />
                <Image
                  src="/images/sweepstakes-closed-hero-mobile.png"
                  alt=""
                  className="closed-hero-mobile"
                />
                <div className="copy-container">
                  <h1>Sweepstakes Closed</h1>
                  <p>
                    Seagram’s 7 ‘Regulars Rules’ National Dive Bar Day Sweepstakes
                    has ended. Check back soon for new offers.
                  </p>
                  <div className="social-logo-container">
                    <a href="https://www.instagram.com/seagrams7" target="_blank">
                      <Image src="/images/instagram-logo.png" alt="" />
                    </a>
                    <a
                      href="https://www.facebook.com/Seagrams-7-811915025605751/"
                      target="_blank"
                    >
                      <Image src="/images/facebook-logo.png" alt="" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="dive-bar-content mission">
                <Container style={{ maxWidth: '1190px' }}>
                  <h2>Our Mission</h2>
                  <p className="mission-content">
                    Dive bars have always been there for us, that’s why we’re
                    partnering with the Main Street Alliance to return the favor. By
                    raising funds for grants, we can help keep dive bars open and keep
                    refreshing-tasting 7&7s made by no-nonsense bartenders flowing.
                    Donation to Main Street Alliance{" "}
                    <a href="https://mainstreetalliance.org" target="_blank">
                      (https://mainstreetalliance.org)
                    </a>
                    will support small businesses, including dive bars.
                  </p>
                  <p className="mission-dive">
                    The world needs dive bars, so let’s keep the dive alive.
                  </p>
                  <Image
                    className="mission-logo img-fluid"
                    src="../images/mission-logo-desktop.png"
                  />
                </Container>
              </Col>
            </Row>
            <Row>
              <Col className="give-back-partner">
                <Container style={{ maxWidth: '1110px' }}>
                  <h2>Our Giveback Partner</h2>
                  <Image
                    className="main-street-img img-fluid"
                    src="/images/main-street-logo.png"
                    alt="Main Street Alliance"
                  />
                  <p className="partner-content">
                    For years, The Main Street Alliance has been the voice for small
                    business values in the United States. Their focus is to help
                    create policies that level the playing field so that small
                    businesses can continue to create important jobs in communities
                    across the country. Their vision for the economy prioritizes
                    investment in small businesses and their employees, customers
                    and communities, with a belief that good business requires us to
                    look beyond short-term profits and exploitative business
                    practices and instead focus on making investments that create
                    sustainable workplaces and prosperous economies.
                  </p>
                </Container>
              </Col>
            </Row>
            </motion.div>
          </Container>
        
      </div>
    </HelmetProvider>
  );
}