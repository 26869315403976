import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import "../../index.css";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Domain_Name } from "../../constant";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import NewsletterSubmit from "./NewsletterSubmit";

export default function Newsletter(props) {
  const parse = require("html-react-parser");
  const location = useLocation(); // React Hook
  const [showSubmit, setShowSubmit] = useState(false);
  const checkRoute = location.pathname;
  let routUrl = Domain_Name + checkRoute;
  const [responseApi, setResponseApi] = useState({});

  const [state, setState] = useState([
    {
      key: "AL",
      value: "Alabama",
    },
    {
      key: "AK",
      value: "Alaska",
    },
    {
      key: "AZ",
      value: "Arizona",
    },
    {
      key: "AR",
      value: "Arkansas",
    },
    {
      key: "CA",
      value: "California",
    },
    {
      key: "CO",
      value: "Colorado",
    },
    {
      key: "CT",
      value: "Connecticut",
    },
    {
      key: "DE",
      value: "Delaware",
    },
    {
      key: "DC",
      value: "District Of Columbia",
    },
    {
      key: "FL",
      value: "Florida",
    },
    {
      key: "GA",
      value: "Georgia",
    },
    {
      key: "HI",
      value: "Hawaii",
    },
    {
      key: "ID",
      value: "Idaho",
    },
    {
      key: "IL",
      value: "Illinois",
    },
    {
      key: "IN",
      value: "Indiana",
    },
    {
      key: "IA",
      value: "Iowa",
    },
    {
      key: "KS",
      value: "Kansas",
    },
    {
      key: "KY",
      value: "Kentucky",
    },
    {
      key: "LA",
      value: "Louisiana",
    },
    {
      key: "ME",
      value: "Maine",
    },
    {
      key: "MD",
      value: "Maryland",
    },
    {
      key: "MA",
      value: "Massachusetts",
    },
    {
      key: "MI",
      value: "Michigan",
    },
    {
      key: "MN",
      value: "Minnesota",
    },
    {
      key: "MS",
      value: "Mississippi",
    },
    {
      key: "MO",
      value: "Missouri",
    },
    {
      key: "MT",
      value: "Montana",
    },
    {
      key: "NE",
      value: "Nebraska",
    },
    {
      key: "NV",
      value: "Nevada",
    },
    {
      key: "NH",
      value: "New Hampshire",
    },
    {
      key: "NJ",
      value: "New Jersey",
    },
    {
      key: "NM",
      value: "New Mexico",
    },
    {
      key: "NY",
      value: "New York",
    },
    {
      key: "NC",
      value: "North Carolina",
    },
    {
      key: "ND",
      value: "North Dakota",
    },
    {
      key: "OH",
      value: "Ohio",
    },
    {
      key: "OK",
      value: "Oklahoma",
    },
    {
      key: "OR",
      value: "Oregon",
    },
    {
      key: "PA",
      value: "Pennsylvania",
    },
    {
      key: "RI",
      value: "Rhode Island",
    },
    {
      key: "SC",
      value: "South Carolina",
    },
    {
      key: "SD",
      value: "South Dakota",
    },
    {
      key: "TN",
      value: "Tennessee",
    },
    {
      key: "TX",
      value: "Texas",
    },
    {
      key: "UT",
      value: "Utah",
    },
    {
      key: "VT",
      value: "Vermont",
    },
    {
      key: "VA",
      value: "Virginia",
    },
    {
      key: "WA",
      value: "Washington",
    },
    {
      key: "WV",
      value: "West Virginia",
    },
    {
      key: "WI",
      value: "Wisconsin",
    },
    {
      key: "WY",
      value: "Wyoming",
    },
  ]);

  const handleCallback = (childData) => {

    setShowSubmit(childData)
  }

  const date = new Date().toISOString().split("T")[0];

  function differenceInYears(inputDate) {
    const ageDifMs = Date.now() - inputDate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from current and input date
    const ageInYears = Math.abs(ageDate.getUTCFullYear() - 1970); //calculate age
    return ageInYears;
  }
  const newsletterSchema = Yup.object().shape({
    CP_FirstName: Yup.string().required("First Name is required."),
    CP_LastName: Yup.string().required("Last Name is required."),
    CP_DOB: Yup.date()
      .required("DOB is required.")
      .test("DOB", "You must be at least 21 years old to enter.", function (value) {
        return differenceInYears(new Date(value)) >= 21;
      }),
    CP_EmailId: Yup.string().email('Please enter valid email address.').required("Email is required."),
    PR_4_324_1_freetext: Yup.string().required("Zip Code is required."),
    PR_4_466_1_freetext: Yup.string().required("State Code is required."),
    //PR_1_64_1: Yup.bool().oneOf([true], 'Please tick this box if you want to proceed.'),
    newsletter_optin_terms: Yup.bool().oneOf([true], 'Please tick this box if you want to proceed.'),
    // newsletter_optin_terms: Yup.string().required("Please tick this box if you want to proceed."),
  });

  const formik = useFormik({

    initialValues: {
      PR_4_367_1_freetext: "",
      CP_FirstName: "",
      CP_LastName: "",
      CP_DOB: "",
      CP_EmailId: "",
      PR_4_466_1_freetext: "",
      PR_4_324_1_freetext: "",
      //PR_1_64_1: false,
      newsletter_optin_terms: false,
      CP_WebsiteUrl: routUrl,
    },
    validationSchema: newsletterSchema,
    onSubmit: (values, { resetForm }) => {
      // setValidationChange(true)
      var dataForm = {
        HD_PromoCode: "DNA107SEA0322WA0001",
        HD_IsDefaultFlag: "1",
        PR_4_367_1_freetext: values.PR_4_367_1_freetext,
        PR_4_92_1: "222",
        HD_EmailType: "1",
        HD_PR_1_64_1_Brand: "100",
        HD_PR_1_64_1_Comm: "1",
        CP_FirstName: values.CP_FirstName,
        CP_LastName: values.CP_LastName,
        CP_DOB: values.CP_DOB,
        CP_EmailId: values.CP_EmailId,
        PR_4_466_1_freetext: values.PR_4_466_1_freetext,
        PR_4_324_1_freetext: values.PR_4_324_1_freetext,
        //PR_1_64_1: values.PR_1_64_1 === true ? "1" : "0",
        newsletter_optin_terms:
          values.newsletter_optin_terms === true ? "true" : "false",
        CP_WebsiteUrl: routUrl,
      };
      // resetForm();
      
    
      //var url = `/api/mulesoft`; 
     //var url = `/api/klaviyo`;
     
      var url = `/api/optinsalesforceform`;
     
      
      axios
        .post(url, dataForm)
        .then(function (response) {
          setResponseApi(response.data)
          setShowSubmit(true);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            "event": "sign_up",
            "step": "success",
            "sign_up_type": "newsletter",
            "form_name": "newsletter",
            "form_location": "inline",
            "detail": "newsletter opt-in",
            "optin_brands": "seagrams7"
          });
          console.log(window.dataLayer);
        })
        .catch(function (error) {
          setResponseApi(error)
          setShowSubmit(true);
        });
    },
  });

  useEffect(() => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "event": "sign_up_step",
        "step": "form shown",
        "sign_up_type": "newsletter",
        "form_name": "newsletter",
        "form_location": "inline"
      });
      console.log(window.dataLayer);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{props.newsLetterKey.title}</title>
          <meta name="description" content={props.newsLetterKey.description} />
          <meta name="keywords" content={props.newsLetterKey.keywords} />
          <link rel="icon" href="/favicon.ico" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Seagram's 7" />
          <meta name="twitter:title" content={props.newsLetterKey.title} />
          <meta
            name="twitter:description"
            content={props.newsLetterKey.description}
          />
          <meta property="og:title" content={props.newsLetterKey.title} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={props.newsLetterKey.canonicalTag} />
          <meta
            property="og:image"
            content={`${Domain_Name + "/" + props.newsLetterKey.primaryImage}`}
          />
          <meta
            property="og:description"
            content={props.newsLetterKey.description}
          />
          <link rel="canonical" href={props.newsLetterKey.canonicalTag} />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          exit={{ opacity: 0 }}
        >
          {
            showSubmit && showSubmit === true ? <NewsletterSubmit apiResponse={responseApi} isShowRegisterForm={handleCallback} /> : <section className="newsLetter pb-5 mb-3">
              <Container className="mx-auto" style={{ maxWidth: "1000px" }}>
                <div className="stayTouch text-center">
                  <h1>Stay in Touch</h1>
                  <p>
                    Sign up today for news, exclusive recipes and the latest
                    events.
                  </p>
                </div>

                <div className="justify-content-center">
                  <Form className="touchForm" id="cidbForm">
                    <Row>
                      <Form.Group className="mb-3 input" controlId="HD_PromoCode">
                        <Form.Control
                          type="hidden"
                          name="HD_PromoCode"
                          value="DNA107SEA0322WA0001"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 input"
                        controlId="HD_IsDefaultFlag"
                      >
                        <Form.Control
                          type="hidden"
                          name="HD_IsDefaultFlag"
                          value="1"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 input"
                        controlId="PR_4_367_1_freetext"
                      >
                        <Form.Control
                          type="hidden"
                          name="PR_4_367_1_freetext"
                          value=""
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 input" controlId="PR_4_92_1">
                        <Form.Control
                          type="hidden"
                          name="PR_4_92_1"
                          value="222"
                        />
                      </Form.Group>
                      <Col lg={6} md={6} xs={12}>
                        <Form.Group
                          className="mb-3 input"
                          controlId="CP_FirstName"
                        >
                          <Form.Control
                            name="CP_FirstName"
                            value={formik.values.CP_FirstName}
                            onChange={formik.handleChange("CP_FirstName")}
                            placeholder="First Name*"
                          />
                          {formik.errors.CP_FirstName && formik.touched.CP_FirstName ? (
                            <span>
                              {formik.errors.CP_FirstName}
                            </span>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6} xs={12}>
                        <Form.Group
                          className="mb-3 input"
                          controlId="CP_LastName"
                        >
                          <Form.Control
                            name="CP_LastName"
                            value={formik.values.CP_LastName}
                            onChange={formik.handleChange("CP_LastName")}
                            placeholder="Last Name*"
                          />
                          {formik.errors.CP_LastName && formik.touched.CP_LastName ? (
                            <span>
                              {formik.errors.CP_LastName}
                            </span>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6} xs={12}>
                        <Form.Group className="mb-3 input" controlId="CP_DOB">
                          <Form.Control
                            className={formik.values.CP_DOB ? 'rem-placeholder' : ''}
                            name="CP_DOB"
                            type="date"
                            selected={(formik.values.CP_DOB && new Date(formik.values.CP_DOB)) || null}
                            value={formik.values.CP_DOB}
                            onChange={formik.handleChange("CP_DOB")}
                            placeholder="dd-mm-yyyy*"
                            max={date}
                          />
                          {formik.errors.CP_DOB && formik.touched.CP_DOB ? (
                            <span>
                              {formik.errors.CP_DOB}
                            </span>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6} xs={12}>
                        <Form.Group
                          className="mb-3 input"
                          as={Col}
                          controlId="CP_EmailId"
                        >
                          <Form.Control
                            name="CP_EmailId"
                            type="email"
                            value={formik.values.CP_EmailId}
                            onChange={formik.handleChange("CP_EmailId")}
                            placeholder="Email"
                          />
                          {formik.errors.CP_EmailId && formik.touched.CP_EmailId ? (
                            <span>
                              {formik.errors.CP_EmailId}
                            </span>
                          ) : null}

                        </Form.Group>
                        <Form.Group
                          className="mb-3 input"
                          controlId="HD_EmailType"
                        >
                          <Form.Control
                            name="HD_EmailType"
                            type="hidden"
                            value="1"
                            runat="server"
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} xs={12}>
                        <Form.Group
                          className="mb-3 input"
                          as={Col}
                          controlId="PR_4_324_1_freetext"
                        >
                          <Form.Control
                            name="PR_4_324_1_freetext"
                            value={formik.values.PR_4_324_1_freetext}
                            onChange={formik.handleChange("PR_4_324_1_freetext")}
                            placeholder="Zip Code*"
                          />
                          {formik.errors.PR_4_324_1_freetext && formik.touched.PR_4_324_1_freetext ? (
                            <span>
                              {formik.errors.PR_4_324_1_freetext}
                            </span>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} xs={12}>
                        <Form.Group
                          className="mb-3 input"
                          as={Col}
                          controlId="PR_4_466_1_freetext"
                        >
                          <Form.Select
                            name="PR_4_466_1_freetext"
                            className="input"
                            value={formik.values.PR_4_466_1_freetext}
                            onChange={formik.handleChange("PR_4_466_1_freetext")}
                          >
                            <option
                              defaultValue=""
                              disabled=""
                              hidden=""
                              value=""
                            >
                              State*
                            </option>
                            {state.length > 0 &&
                              state.map((option) => (
                                <option key={option.key} value={option.key}>
                                  {option.value}
                                </option>
                              ))}
                          </Form.Select>
                          {formik.errors.PR_4_466_1_freetext && formik.touched.PR_4_466_1_freetext ? (
                            <span>
                              {formik.errors.PR_4_466_1_freetext}
                            </span>
                          ) : null}
                        </Form.Group>

                       {/*} <Form.Group
                          className="mb-3 input"
                          as={Col}
                          controlId="HD_PR_1_64_1_Brand"
                        >
                          <Form.Control
                            name="HD_PR_1_64_1_Brand"
                            type="hidden"
                            value="100"
                            runat="server"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 input"
                          as={Col}
                          controlId="HD_PR_1_64_1_Comm"
                        >
                          <Form.Control
                            name="HD_PR_1_64_1_Comm"
                            type="hidden"
                            value="1"
                            runat="server"
                          />
                        </Form.Group> */}
                      </Col>

                     {/* <Form.Group className="input" id="PR_1_64_1">
                        <Form.Check
                          className={formik.errors.PR_1_64_1 && formik.touched.PR_1_64_1 ? "error" : ""}
                          name="PR_1_64_1"
                          checked={formik.values.PR_1_64_1}
                          value={formik.values.PR_1_64_1}
                          onChange={formik.handleChange("PR_1_64_1")}
                          type="checkbox"
                          id="PR_1_64_1"
                          label="* Yes, I would like to receive special offers and promotions from Seagram's 7 Crown by email."
                        />
                        {formik.errors.PR_1_64_1 && formik.touched.PR_1_64_1 ? (
                          <span className="ms-4">
                            {formik.errors.PR_1_64_1}
                          </span>
                        ) : null}

                      </Form.Group> */}

                      <Form.Group className="input" id="newsletter_optin_terms">
                        <Form.Check
                          className={formik.errors.newsletter_optin_terms && formik.touched.newsletter_optin_terms ? "error" : ""}
                          name="newsletter_optin_terms"
                          value={formik.values.newsletter_optin_terms}
                          checked={formik.values.newsletter_optin_terms}
                          type="checkbox"
                          id="newsletter_optin_terms"
                          // required={true}
                          onChange={formik.handleChange("newsletter_optin_terms")}
                          // label="I accept Diageo's Conditions of Use and acknowledge the Privacy and Cookie Notice. *"   <a href='https://www.thebar.com/en-gb' target='_blank'>TheBar.com</a>  
                          label={parse(
                            "* Tick here if you would like us to use your email to keep you informed about products, services and events from Seagram's 7 Crown & other Diageo brands including <a href='https://www.thebar.com/en-gb' target='_blank'>TheBar.com</a>. You can unsubscribe at any time. By signing up, you accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.seagrams7.com/TnC_uen.html?locale=uen-us' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.seagrams7.com/PrivacyPolicy_uen.html?locale=uen-us' target='_blank'>Privacy & Cookie Notice</a>."
                          )}
                        />

                        {formik.errors.newsletter_optin_terms && formik.touched.newsletter_optin_terms ? (
                          <span className="ms-4">
                            {formik.errors.newsletter_optin_terms}
                          </span>
                        ) : null}

                      </Form.Group>

                      <Form.Group className="btnDiv text-center">
                        <Button
                          className="btn btn-lg mx-auto mt-3 mb-4 btnDanger"
                          type="submit"
                          onClick={formik.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      </Form.Group>

                      <div className="smallText text-center">
                        <small>
                          Seagram's 7 Crown values and respects your privacy. You
                          can unsubscribe at any time.
                        </small>
                      </div>
                    </Row>
                  </Form>
                </div>
              </Container>
            </section>}
        </motion.div>
      </HelmetProvider>
    </>
  );
}
