import React from "react";
// reactstrap components
import { Row, Container, Col } from "reactstrap";
import Image from "react-bootstrap/Image";
import NewsletterModal from "./NewsletterModal";
function Footer() {
  return (
    <>
    <footer>      
      <Container style={{maxWidth: '1100px'}}>
        <Row>
          <Col className="justify-content-center">            
            <Image src="/images/Diageo_Logo.svg" alt="Diageo" />
            <p className="mt-4 mb-3">
              <strong>Drink Responsibly </strong>
            </p>  
            <div id="footer"></div>
          </Col>
        </Row>
      </Container>
    </footer>
    <NewsletterModal />
    </>  
    
  );
}

export default Footer;
