import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Domain_Name } from "../../constant";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
export default function NotFound(props) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{props.notFoundKey.title}</title>
          <meta name="description" content={props.notFoundKey.description} />
          <meta name="keywords" content={props.notFoundKey.keywords} />
          <link rel="icon" href="/favicon.ico" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Seagram's 7" />
          <meta name="twitter:title" content={props.notFoundKey.title} />
          <meta
            name="twitter:description"
            content={props.notFoundKey.description}
          />
          <meta property="og:title" content={props.notFoundKey.title} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={props.notFoundKey.canonicalTag} />
          <meta
            property="og:image"
            content={`${Domain_Name +'/'+ props.notFoundKey.primaryImage}`}
          />
          <meta
            property="og:description"
            content={props.notFoundKey.description}
          />
          <link rel="canonical" href={props.notFoundKey.canonicalTag} />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01]
          }}
          exit={{ opacity: 0 }}
        >
        <section className="error-page">
          <Container style={{maxWidth: '740px'}}>
            <div>
              <h1 className="text-center"><span>404</span> ERROR</h1>
              <h2>THIS IS NOT THE PAGE YOU’RE LOOKING FOR</h2>
              <p>
                Sorry, it appears the page you were looking for doesn’t exist
                anymore or may have moved. Go back to our <Link to="/">Home Page</Link> to explore
                more.
              </p>
            </div>
          </Container>
        </section>
        </motion.div>
      </HelmetProvider>
    </>
  );
}