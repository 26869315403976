import React, { useState, useEffect } from "react";
import Select from "react-select";

const MultiDropdown = ({ name, selectedOptions: initialSelectedOptions, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions || []);

  // Update state when parent component provides new selected options
  useEffect(() => {
    setSelectedOptions(initialSelectedOptions || []);
  }, [initialSelectedOptions]);

  const multiDropOptions = [
    { value: "Seagram’s 7 American Blended Whiskey", label: "Seagram’s 7 American Blended Whiskey" },
    { value: "Seagram’s 7 Dark Honey", label: "Seagram’s 7 Dark Honey" },
    { value: "Seagram’s 7 Crown Apple", label: "Seagram’s 7 Crown Apple" },
    // ...other options
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '1rem ', // Set the font size to 14 pixels
       fontFamily: 'CentGothWGL',
       padding: '2px 10px',
       color: '#333333',
       letterspacing: '0.9px',
       lineheight: '1.1',
       textrendering: 'geometricPrecision',
       margin: '0',
       ':hover': {
        backgroundColor: '#1a73e8', // Change background color on hover
        color: '#fff', // Change text color on hover
      },
     }),
     
  };
  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <div>
      <Select
        isMulti
        inputId={name}
        inputProps={{ name }}
        value={selectedOptions}
        onChange={handleChange}
        options={multiDropOptions}
        className="mb-3 multioptions"
        placeholder="Select One Or More Product*"
        styles={customStyles}
      />
      <input type="hidden" name={name} value={selectedOptions.map(item => item.value)} />
      {/* JSON.stringify(selectedOptions) */}
    </div>
  );

};

export default MultiDropdown;
