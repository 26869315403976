import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMaparker } from "@fortawesome/fontawesome-free-solid";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Domain_Name } from "../../constant";
import { motion } from "framer-motion";

const WheretoBuySeagram = (props) => {
  const [lang, setLang] = useState(0);
  const [lat, setLat] = useState(0);
  const navigate = useNavigate();
  const isMountedRef = useRef(false);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'customEvent',
      'eventCategory': 'WTB Page Load',
      'eventAction': 'Page load',
      'eventLabel': 'Seagram 7',
      'dimension14': 'Seagram 7',
      'eventInteraction': true
    });
    console.log(window.dataLayer );

    window.scrollTo(0, 0);
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      const geocoder = new MapboxGeocoder({
        accessToken:
          "pk.eyJ1IjoibXdrcmllZ2VyIiwiYSI6ImNqcDA1NGQ4bDA0cjIzd3BpbGl5NzVvcXMifQ.2Cs5UE0BiZsh_wt36xHRoA",
        types: "postcode,address",
        placeholder: "Enter Location",
        countries: "us",
      });
      geocoder.addTo("#location");
      geocoder.on("result", (e) => {
        setLang(e.result.center[0]);
        setLat(e.result.center[1]);
      });
    }
  }, []);


  const handleSearch = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'customEvent',
      'eventCategory': 'WTB_Find_Near_Me',
      'eventAction': 'Find now',
      'eventLabel': 'Seagram 7',
      'dimension14': 'Seagram 7',
      'eventInteraction': true
    });
    console.log(window.dataLayer );
    let redirect_url = '/where-to-buy-seagrams/find-near-me/';
    if (lang && lat) {
      navigate(redirect_url + '?lat=' + lat + '&lng=' + lang);
    } else {
      navigate(redirect_url);
    }
  };

  const CallShopOnline = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'customEvent',
      'eventCategory': 'WTB_Shop_Online',
      'eventAction': 'Shop products',
      'eventLabel': 'Seagram 7',
      'dimension14': 'Seagram 7',
      'eventInteraction': true
    });

    console.log(window.dataLayer );
    navigate('/where-to-buy-seagrams/shop-online');
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{props.whereToBuySeagramKey.title}</title>
          <meta
            name="description"
            content={props.whereToBuySeagramKey.description}
          />
          <meta name="keywords" content={props.whereToBuySeagramKey.keywords} />
          <link rel="icon" href="/favicon.ico" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Seagram's 7" />
          <meta
            name="twitter:title"
            content={props.whereToBuySeagramKey.title}
          />
          <meta
            name="twitter:description"
            content={props.whereToBuySeagramKey.description}
          />
          <meta
            property="og:title"
            content={props.whereToBuySeagramKey.title}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={props.whereToBuySeagramKey.canonicalTag}
          />
          <meta
            property="og:image"
            content={`${Domain_Name + "/" + props.whereToBuySeagramKey.primaryImage}`}
          />
          <meta
            property="og:description"
            content={props.whereToBuySeagramKey.description}
          />
          <link
            rel="canonical"
            href={props.whereToBuySeagramKey.canonicalTag}
          />
        </Helmet>
        <link
          rel="stylesheet"
          href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.css"
          type="text/css"
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01]
          }}
          exit={{ opacity: 0 }}
          className="where-buy-seagram"
        >
          <Container className="mx-auto" style={{ maxWidth: '1000px' }}>
            <Row>
              <div className="filter">
                <Col md={12}>
                  <h1 className="lead text-center">Where To Buy <br /> Seagram's 7 Crown</h1>
                </Col>
              </div>
            </Row>
            <Row className="mt-120">
              <Col lg={6} md={6} className="find-section">
                <div className="inner">
                  <h1 className="lead text-center">Find Near Me</h1>
                  <div className="icon">
                    <FontAwesomeIcon icon={["fas", "fa-map-marker-alt"]} />
                  </div>
                  <div className="copy">
                    <p>
                      Use our store locator to find what’s available at a
                      retailer or restaurant/bar near you
                    </p>
                  </div>
                  <div className="formWrapper">
                    <div id="location" className="mb-4"></div>
                    <Button className="btn btn-lg btnDanger" onClick={handleSearch} id='wtb-search' type="submit">Find Now</Button>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} className="shop-online-box">
                <div className="inner">
                  <h1 className="lead text-center">Shop Online</h1>
                  <div className="icon">
                    <FontAwesomeIcon icon={["fas", "fa-shopping-cart"]} />
                  </div>
                  <div className="copy p-3">
                    <p></p>
                  </div>
                  <div className="linkWrapper mt-4">
                    <Button className="btn btn-lg btnDanger" onClick={CallShopOnline} type="submit">Shop Products</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </motion.div>
      </HelmetProvider>
    </>
  );
};
export default WheretoBuySeagram;
